<template>
  <div>
    <resumo :resumo="resumo" :permission="permission" />
    <listagem
      ref="listagem"
      :tela="permission"
      :headers="headers.filter((item) => item.display !== false)"
      :data="agendamentos"
      :dataTotal="dataTotal"
      :pageSize="page_size"
      @handleGet="handleGet"
      @getDownload="download"
      :canDownload="canDownload"
    />
  </div>
</template>

<script>
import Listagem from '@/components/patio/GerenciamentoServico/listagem/Componente.vue'
import Resumo from '@/components/patio/GerenciamentoServico/Resumo.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { getUrl, downloadUrl } from '@/utils/index'

export default {
  name: 'RelatorioCargaDescargaListConcluidos',
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  components: {
    Listagem,
    Resumo,
  },
  data() {
    return {
      page_size: 500,
      dataTotal: 0,
      resumo: undefined,
    }
  },
  computed: {
    ...mapState('patio', ['agendamentos', 'regioes']),
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioCargaDescarga', ['params']),
    permission() {
      return Sequences.CargaDescarga.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canDownload() {
      return Object.keys(this.params).length > 0
    },
  },
  methods: {
    ...mapMutations('patio', ['SET_AGENDAMENTOS']),
    ...mapActions('patio', [
      'getAllAgendamentosByParams',
      'getAgendamentoRelatorio',
      'getRelatorioServico',
      'getRegioesColaborator',
      'getRelatorioServicoDetalhado',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    handleGet(event) {
      this.options = event
      this.page_size = event.itemsPerPage
      this.handleGetAgendamentos(event.page)
    },
    async download() {
      await this.getDownload()
    },
    async getDownload() {
      if (!this.canDownload) return
      if (
        this.empresaAtual.representantes.length > 0 &&
        this.params.regiao == undefined
      ) {
        await this.getRegioesColaborator()

        if (this.regioes.length > 0) {
          this.params.regiao = this.regioes.map((regiao) => regiao.public_id)
          this.params.representante = this.user.public_id
        }
      }
      this.$emit('loading')
      let data = []
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')
        const path = 'api/v1/relatorios/agendamentos/download/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Não foi possível baixar a planilha. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
      return data
    },
    async handleGetAgendamentos(page = 1, new_page_size = undefined) {
      if (!Object.keys(this.params).length) return

      if (new_page_size) {
        this.page_size = new_page_size
      }
      const page_size = this.page_size
      this.$emit('loading')
      try {
        const { sortBy, sortDesc } = this.options
        let ordering
        if (
          sortBy &&
          sortBy.length === 1 &&
          sortDesc &&
          sortDesc.length === 1
        ) {
          ordering = sortBy[0]
          ordering = sortDesc[0] ? '-' + ordering : ordering
          ordering = ordering.replaceAll('.', '__')
        }
        if (
          this.empresaAtual.representantes.length > 0 &&
          this.params.regiao == undefined
        ) {
          await this.getRegioesColaborator()
          if (this.regioes.length > 0) {
            this.params.regiao = this.regioes.map((regiao) => regiao.public_id)
            this.params.representante = this.user.public_id
          }
        }
        const { results, count, resumo } = await this.getAgendamentoRelatorio({
          page,
          page_size: (page_size === -1 && this.dataTotal) || page_size,
          ordering,
          proprietario: this.empresaAtual.public_id,
          ...this.params,
        })
        this.resumo = resumo
        this.data = results
        this.dataTotal = count
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      } finally {
        this.$emit('loaded')
      }
    },
  },
}
</script>

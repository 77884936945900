<template>
  <div>
    <v-col>
      <v-autocomplete
        v-model="value"
        :label="label"
        :prepend-icon="icon"
        :items="selectSubProdutos"
        item-text="descricao"
        return-object
        @click="selectAllSubProducts"
        @input="$emit('input', $event)"
      ></v-autocomplete>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'RelatorioCargaDescargaListFiltroModalItemInputSubProduto',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    produto: {
      required: true,
    },
  },
  data() {
    return {
      selectSubProdutos: [],
    }
  },
  computed: {
    ...mapState('subProdutos', ['subProdutos']),
    ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapMutations('subProdutos', ['setSubProduto']),
    async selectAllSubProducts() {
      if (this.produto.id != null) {
        this.setSubProduto(this.produto.subprodutos)
        this.selectSubProdutos = [...this.subProdutos]
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
      }
    },
  },
  async created() {},
}
</script>
